import React, { createContext, useContext, useState, useEffect } from 'react';
import { useSearchParams } from 'react-router-dom';
import { source_list } from '../components/Dashboard'; // Import your source list

const FilterContext = createContext();

export const useFilterContext = () => useContext(FilterContext);

export const FilterProvider = ({ children }) => {
  const [filters, setFilters] = useState({
    ccValue: '',
    sourceValues: {}, // Initial state for sources
    regionValues: {}, // Initial state for regions
  });

  const [searchParams, setSearchParams] = useSearchParams();

  // Initialize filter states from the URL on component mount
  useEffect(() => {
    const initialSourceValues = {};
    const initialRegionValues = {};
  
    // Initialize source values based on source_list and URL parameters
    source_list.forEach((source) => {
      initialSourceValues[source.name] = searchParams.getAll('fq').some(fq => fq.includes(`source:${source.name}`));
    });
  
    // Initialize region values based on URL parameters
    const region_list = [
      { name: "EU-wide", key: "eu" },
      { name: "Bundesweit", key: "bundesweit" },
      { name: "Berlin only", key: "berlin" }
    ];
  
    region_list.forEach((region) => {
      initialRegionValues[region.key] = searchParams.getAll('fq').some(fq => fq.includes(`region:${region.key}`));
    });
  
    // Initialize CC Center value from URL using the same fq approach
    const initialCcValue = searchParams.getAll('fq').find(fq => fq.startsWith('cc:'))?.split(':')[1] || 'none';
  
    setFilters({
      ccValue: initialCcValue,
      sourceValues: initialSourceValues,
      regionValues: initialRegionValues,
    });
  }, [searchParams]);
  
  const applyFilters = () => {
    const newParams = new URLSearchParams(searchParams);
  
    // Clear existing filter queries first
    newParams.delete('cc');
    newParams.delete('fq');
  
    // Handle the CC Center filter
    if (filters.ccValue && filters.ccValue !== 'none') {
        newParams.append('fq', `cc:${filters.ccValue}`);
    }
  
    // Handle Source filters - combine all selected sources into a single 'fq'
    const selectedSources = Object.entries(filters.sourceValues)
        .filter(([key, value]) => value) // Filter selected sources
        .map(([key]) => `source:${key}`) // Map them into the 'source' format
        .join(' '); // Join them with a space
  
    if (selectedSources) {
        newParams.append('fq', selectedSources); // Add as a single 'fq' parameter for sources
    }
  
    // Handle Region filters - combine all selected regions into a single 'fq'
    const selectedRegions = Object.entries(filters.regionValues)
        .filter(([key, value]) => value) // Filter selected regions
        .map(([key]) => `region:${key}`) // Map them into the 'region' format
        .join(' '); // Join them with a space
  
    if (selectedRegions) {
        newParams.append('fq', selectedRegions); // Add as a single 'fq' parameter for regions
    }
  
    // Ensure UI is updated after query string is changed
    setSearchParams(newParams);
  };
  

  const handleSource = (event) => {
    const newSourceValues = { ...filters.sourceValues, [event.target.name]: event.target.checked };
    setFilters((prev) => ({ ...prev, sourceValues: newSourceValues }));
  };

  const handleRegion = (event) => {
    const newRegionValues = { ...filters.regionValues, [event.target.name]: event.target.checked };
    setFilters((prev) => ({ ...prev, regionValues: newRegionValues }));
  };

  const handleChangeCC = (event) => {
    const newCcValue = event.target.value;
    setFilters((prevFilters) => ({
        ...prevFilters,
        ccValue: newCcValue,
    }));
};

  const clearSelection = () => {
    const clearedSourceValues = {};
    source_list.forEach((source) => {
      clearedSourceValues[source.name] = false;
    });

    const clearedRegionValues = {};
    const region_list = [
      { name: "EU-wide", key: "eu" },
      { name: "weit", key: "bundesweit" },
      { name: "Berlin only", key: "berlin" }
    ];
    region_list.forEach((region) => {
      clearedRegionValues[region.key] = false;
    });

    setFilters({
      ccValue: 'none',
      sourceValues: clearedSourceValues,
      regionValues: clearedRegionValues,
    });
  };

  return (
    <FilterContext.Provider
      value={{
        filters,
        setFilters,
        handleSource,
        handleRegion,
        applyFilters,
        clearSelection,
        handleChangeCC,
      }}
    >
      {children}
    </FilterContext.Provider>
  );
};
