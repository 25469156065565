import React, { useEffect, useState,useRef } from 'react';
import {
  Box,
  Button,
  Typography,
  useMediaQuery,
  useTheme
} from '@mui/material';

import ResponsiveAppBar from '../components/ResponsiveAppBar';
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import AOS from 'aos';
import 'aos/dist/aos.css';
import { debounce } from 'lodash';

import Dashboard from '../components/Dashboard';  
import { useAppContext } from '../Contexts/AppContext';
import Survey from '../components/Survey';

function SecondPage() {
  const { searchBarInput, setSearchBarInput, searchFromSearchBar, handleLoginShow, isAuth, ccFilterValue, handleCCChange, sourceValues, handleSource, regionValues, handleRegion, sortByValue, handleSortBy } = useAppContext();

  const [toggleDashboard, setToggleDashboard] = useState(true);
  const toggleDashboards = () => setToggleDashboard(!toggleDashboard);
  const [isAppBarFixed, setIsAppBarFixed] = useState(false);


  const [lastScrollTop, setLastScrollTop] = useState(0); // State to keep track of the last scroll position
  const appBarRef = useRef(null); // Ref for the AppBar element

  // Function to fix AppBar when it touches the top of the screen
  const checkAppBarPosition = () => {
    const scrollY = window.pageYOffset || document.documentElement.scrollTop;
    const threshold = 100; // Added threshold to avoid rapid changes
    if (appBarRef.current) {
      const appBarPosition = appBarRef.current.getBoundingClientRect();
      setIsAppBarFixed(appBarPosition.top <= 0 && scrollY > threshold); // Only fix after scrolling past threshold
    }
    setLastScrollTop(scrollY <= 0 ? 0 : scrollY); // Prevent negative values
  };
  

  useEffect(() => {
    AOS.init({ duration: 1000, once: true });

    // Define the debounced scroll handler
    const handleScrollDebounced = debounce(checkAppBarPosition, 0);

    // Add the scroll listener
    window.addEventListener('scroll', handleScrollDebounced);

    // Initial check in case the page is not at the top when loaded
    checkAppBarPosition();

    // Clean up the event listener when the component unmounts
    return () => {
      window.removeEventListener('scroll', handleScrollDebounced);
    };
  }, []); // Empty dependency array to ensure this runs only once on mount

  return (
    <>
      <Box sx={{ width: '100%', pt: isAppBarFixed ? '64px' : '0', transition: 'padding-top 0.3s ease'}}>

      <Box
          ref={appBarRef}
          sx={{
            position: isAppBarFixed ? 'fixed' : 'static',
            top: 0,
            left: 0,
            right: 0,
            zIndex: (theme) => theme.zIndex.appBar,
            transition: 'all 0.3s ease',
          }}
          data-aos={isAppBarFixed ? null : "fade"}
          >
          <ResponsiveAppBar
            searchBarInput={searchBarInput}
            setSearchBarInput={setSearchBarInput}
            searchFromSearchBar={searchFromSearchBar}
            isFirstPage={false}
            handleLoginShow={handleLoginShow}
            isAuth={isAuth}
            ccFilterValue={ccFilterValue}
            handleCCChange={handleCCChange}
            sourceValues={sourceValues}
            handleSource={handleSource}
            regionValues={regionValues}
            handleRegion={handleRegion}
            sortByValue={sortByValue}
            handleSortBy={handleSortBy}
          />
        </Box>

        <Box sx={{ width: '100%', paddingTop:"30px"}}>
          <Survey />
          {toggleDashboard &&     <Dashboard
                          searchBarInput={searchBarInput}
                          setSearchBarInput={setSearchBarInput}
                          searchFromSearchBar={searchFromSearchBar}
                          isFirstPage={false}
                      />} 

        </Box>
      </Box>
    </>
  );
}

  

export default SecondPage;
