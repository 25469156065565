import React, { createContext, useContext, useState, useEffect, useTransition } from 'react';
import { useSearchParams, useNavigate } from 'react-router-dom';
import { checkSort, formatURLSearchParams, ccToQuery, checkCC, containsBooleanOperators, convertToFuzzyQuery } from "../utils";
import { API_URL } from '../App';

const AppContext = createContext();

const rows = 10; // Number of search results to be displayed per page

export const useAppContext = () => useContext(AppContext);

export const AppProvider = ({ children }) => {
  const [showLogin, setShowLogin] = useState(false);
  const [isAuth, setIsAuth] = useState(false);
  const [urlSearchParams, setUrlSearchParams] = useSearchParams();
  const [ccFilterValue, setCcFilterValue] = useState(checkCC(""));
  const [filterQueries, setFilterQueries] = useState([]);
  const [sourceValues, setSourceValues] = useState({});
  const [regionValues, setRegionValues] = useState({});
  const [sortByValue, setSortByValue] = useState(checkSort(urlSearchParams));
  const [docs, setDocs] = useState([]);
  const [highlights, setHighlights] = useState({});
  const [isPending, startTransition] = useTransition();
  const [isFuzzy, setIsFuzzy] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  const [numTotalDocs, setNumTotalDocs] = useState(-1);
  const [page, setPage] = useState(parseInt(urlSearchParams.get("page") || "1"));
  const [searchBarInput, setSearchBarInput] = useState(urlSearchParams.get("q") || "");
  const [savedSearches, setSavedSearches] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const navigate = useNavigate();

  // Save the search query when clicked on the star icon
  const saveSearch = (query) => {
    if (!savedSearches.includes(query)) {
      setSavedSearches([...savedSearches, query]);
    }
  };

  // Handle search from the search bar
  const searchFromSearchBar = (searchInput) => {
    const query = searchInput.trim() === "" ? "*:*" : searchInput;
    setSearchBarInput(query);

    if (filterQueries.findIndex(item => !item.includes("source:") && !item.includes("region:")) >= 0) {
      let new_filterQueries = [...filterQueries];
      const index = new_filterQueries.findIndex(fq => !fq.includes("source:") && !fq.includes("region:"));
      new_filterQueries.splice(index, 1);
      setFilterQueries(new_filterQueries);
    }

    setSortByValue("relevance");

    // Navigate with the updated query and reset page to 1
    navigate(`/search-results?q=${encodeURIComponent(query)}&page=1`, {
      state: {
        query: query,
        sortBy: 'relevance',
      }
    });
  };

  // Update filter queries when sourceValues or regionValues change
  useEffect(() => {
    const newFilterQueries = [
      ...Object.entries(sourceValues).map(([key, value]) => value && `source:${key}`),
      ...Object.entries(regionValues).map(([key, value]) => value && `region:${key}`)
    ].filter(Boolean);

    setFilterQueries(newFilterQueries);
  }, [sourceValues, regionValues]);

  // Function to fetch search results from the API
  const searchDocs = async (query, filterQueries, sortBy, start) => {
    setIsLoading(true);
    setNumTotalDocs(-1);

    let params = [{ q: query }];

    if (sortBy && sortBy !== "relevance") {
      params.push({ sort: `${sortBy} asc` });
    }

    if (filterQueries.length > 0) {
      filterQueries.forEach((filterQuery) => params.push({ fq: filterQuery }));
    }

    // Add pagination
    params.push({ page });

    // Prepare the URL parameters
    const apiParams = formatURLSearchParams(params);

    try {
      const response = await fetch(
        `${API_URL}${apiParams}&hl=true&hl.method=unified&rows=${rows}&start=${start}`
      );
      const data = await response.json();

      if (!response.ok) {
        throw new Error(data.detail || data.statusText || "An error occurred");
      }

      if (data.response.numFound === 0 && !containsBooleanOperators(query)) {
        const fuzzyQuery = convertToFuzzyQuery(query);
        const fuzzyResponse = await fetch(
          `${API_URL}${formatURLSearchParams([{ q: fuzzyQuery }, ...params.slice(1)])}&hl=true&hl.method=unified&rows=${rows}&start=${start}`
        );
        const fuzzyData = await fuzzyResponse.json();
        setDocs(fuzzyData.response.docs);
        setIsFuzzy(true);
      } else {
        setIsFuzzy(false);
        setDocs(data.response.docs);
      }

      setNumTotalDocs(data.response.numFound);
      setHighlights(data.highlighting);
      setErrorMessage("");
    } catch (error) {
      setErrorMessage(error.message);
      setDocs([]);
      setNumTotalDocs(0);
    } finally {
      setIsLoading(false);
    }
  };

  // Function to handle page change
  const handlePageChange = (newPage) => {
    const query = urlSearchParams.get('q') || '*:*';
    const fqParams = urlSearchParams.getAll('fq');
  
    // Setze die URL mit der neuen Seite und lass den `useEffect` die Suche triggern
    navigate(`/search-results?q=${encodeURIComponent(query)}&page=${newPage}`);
  };
  
  useEffect(() => {
    const query = urlSearchParams.get('q') || '*:*';
    const fqParams = urlSearchParams.getAll('fq');
    const currentPage = parseInt(urlSearchParams.get("page") || "1", 10);
  
    // Daten abrufen, wenn sich die URL ändert
    searchDocs(query, fqParams, sortByValue, (currentPage - 1) * rows);
  }, [urlSearchParams, filterQueries, sortByValue]);
  

  return (
    <AppContext.Provider value={{
      showLogin, setShowLogin,
      isAuth, setIsAuth,
      ccFilterValue, setCcFilterValue,
      sourceValues, setSourceValues,
      regionValues, setRegionValues,
      docs, setDocs,
      highlights, setHighlights,
      isPending, startTransition,
      isFuzzy, setIsFuzzy,
      errorMessage, setErrorMessage,
      searchBarInput, setSearchBarInput,
      searchFromSearchBar, searchDocs,
      savedSearches, setSavedSearches, saveSearch,
      isLoading, setIsLoading,
      numTotalDocs, setNumTotalDocs,
      sortByValue, setSortByValue,
      page, setPage,
      handlePageChange // Ensure pagination handler is available in context
    }}>
      {children}
    </AppContext.Provider>
  );
};
