import React from 'react';
import { FormControl, InputLabel, Select, MenuItem, Box } from '@mui/material';

export default function SortByRadioButtonsGroup({ value, handleClick }) {
    return (
    <Box sx={{ display: 'flex', alignItems: 'center' }}>
            <FormControl size="small" variant="outlined">
                <InputLabel id="sortby-label">Sort By</InputLabel>
                <Select
                    labelId="sortby-label"
                    id="sortby-select"
                    value={value}
                    label="Sort By"
                    onChange={handleClick}
                    sx={{
                        '& .MuiSelect-select': {
                            py: '10px', // padding top and bottom inside the select
                            px: '14px', // padding left and right inside the select
                        },
                        '& .MuiOutlinedInput-notchedOutline': {
                            borderColor: 'rgba(0, 0, 0, 0.23)', // Default border color
                        },
                        '&:hover .MuiOutlinedInput-notchedOutline': {
                            borderColor: 'primary.main', // Border color on hover
                        },
                        '& .Mui-focused .MuiOutlinedInput-notchedOutline': {
                            borderColor: 'primary.main', // Border color when focused
                        },
                        width: 'auto', // Ensure the width is automatically adjusted to content
                    }}
                >
                    <MenuItem value="relevance">Relevance</MenuItem>
                    <MenuItem value="start_date">Start Date</MenuItem>
                    <MenuItem value="end_date">Due Date</MenuItem>
                </Select>
            </FormControl>
        </Box>
    );
}
