import React from 'react';
import { Card, Typography, IconButton, Box } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';

export default function Login({ handleLoginClose }) {
    return (
        <Box
            sx={{
                position: 'fixed',
                top: '50%',
                left: '50%',
                transform: 'translate(-50%, -50%)',
                zIndex: 2000, // High z-index to ensure it's above other content
                width: '90%',
                maxWidth: '400px',
                padding: '1rem',
            }}
        >
            <Card
                sx={{
                    padding: '2rem',
                    boxShadow: '0 4px 20px rgba(0, 0, 0, 0.1)', // Adjusted box-shadow for better visibility
                    borderRadius: '12px',
                    textAlign: 'center',
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'center',
                    position: 'fixed',
                    backgroundColor: '#fff', // Ensure a solid background
                }}
            >
                <IconButton
                    onClick={handleLoginClose}
                    sx={{
                        position: 'absolute',
                        right: '1rem',
                        top: '1rem',
                    }}
                >
                    <CloseIcon />
                </IconButton>
                <Typography variant="h5" sx={{ fontWeight: 'bold', marginBottom: '1rem' }}>
                    Notice
                </Typography>
                <Typography variant="body1" sx={{ wordWrap: 'break-word', padding: '0 1rem' }}>
                    User management is not yet implemented. Please check back later.
                </Typography>
            </Card>
        </Box>
    );
}
