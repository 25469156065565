import React, { useEffect, useState, useTransition } from "react";
import SearchBar from "./components/SearchBar"; // Pfad anpassen
import AwarenessPage from "./maincomponents/AwarenessPage";
import "./App.css";
import { AppProvider } from './Contexts/AppContext';
import { Container } from "@mui/system";
import { useNavigate } from 'react-router-dom';
import {
  Typography,
  Button,
  Box,
} from "@mui/material";
import ResponsiveAppBar from "./components/ResponsiveAppBar";

import HorizontalContantainer from "./maincomponents/HorizontalContainer";

import { useSearchParams } from "react-router-dom";
import Login from "./Login";
import dayjs from "dayjs";
import "dayjs/locale/de";
import { checkAuthCookie} from "./utils";
dayjs.locale("de"); // use loaded locale globally

export const API_URL = window.location.origin + "/solr/core-funding/select";

//export const API_URL = "http://localhost:8983/solr/core-funding/select";

const App = () => {

  useEffect(() => {
    // Update the document title using the browser API
    const auth = checkAuthCookie();
    console.log("Hook ", auth);
  }, []);


  return (
    <AppProvider>

      <div className="App">
        {/* Hauptcontainer für horizontales Scrollen */}
        <HorizontalContantainer>
        </HorizontalContantainer>
        
      </div>

    </AppProvider>
  );
};

export default App;
