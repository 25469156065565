import React from 'react';
import Checkbox from '@mui/material/Checkbox';


export default function DaiCheckBox(label) {
    // Custom Check Box Design for PIA
    return (
        <Checkbox
          {...label}          
          sx={{
            color: "#036b80",
            '&.Mui-checked': {
                color: "#036b80",
            },
          }}
        />
      );
}