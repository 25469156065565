import React from 'react'
import {
    Typography,
    Button,
    Box,
  } from "@mui/material";

import ResponsiveAppBar from '../components/ResponsiveAppBar';
import { AppProvider, useAppContext } from '../Contexts/AppContext';
import AwarenessPage from './AwarenessPage';

function ThirdPage() {

    const {searchBarInput,setSearchBarInput} = useAppContext();

  return (

    <AppProvider>

      <div className="AwarenessPage">
      

<AwarenessPage></AwarenessPage>
</div>

</AppProvider>
  )
}

export default ThirdPage