import React from 'react';
import RadioGroup from '@mui/material/RadioGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import FormControl from '@mui/material/FormControl';
import Button from '@mui/material/Button';
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import KeyboardArrowUpIcon from "@mui/icons-material/KeyboardArrowUp";
import DaiRadio from '../DaiRadio';
import { useFilterContext } from '../Contexts/FilterContext';

export default function CCRadioButtonsGroup() {
  const [open, setOpen] = React.useState(false);
  
  const { filters, handleChangeCC } = useFilterContext(); // Use the context

  const toggleRadioGroup = () => {
    setOpen(!open);
  };

  return (
    <FormControl sx={{
      textAlign: 'left',
      paddingRight: '1.8rem',
      paddingLeft: '1.8rem',
      paddingTop: '0.5em',
      backgroundColor: open ? '#F5F5F5' : 'transparent',
      boxShadow: open ? 3 : 'none',
      borderRadius: 2,
      overflow: 'hidden',
      mb: 2
    }}>
      <Button
        variant="contained"
        endIcon={open ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
        onClick={toggleRadioGroup}
        sx={{
          backgroundColor: '#0C6C81',
          color: '#ffffff',
          '&:hover': { backgroundColor: '#0a5968' },
          borderRadius: '20px',
          textTransform: 'none',
          boxShadow: 'none',
          fontSize: '1rem',
          fontWeight: 'bold',
          padding: '10px 80px',
          width: '100%',
        }}
      >
        CCCenter
      </Button>
      {open && (
        <RadioGroup
          sx={{
            p: 2,
            backgroundColor: '#F5F5F5',
            borderTop: '1px solid #E0E0E0',
          }}
          value={filters.ccValue} // Controlled by context
          onChange={handleChangeCC} // Use context handler
          aria-labelledby="cc-radio-buttons-group-label"
          name="radio-buttons-cc"
        >
          <FormControlLabel value="none" control={<DaiRadio />} label="None" />
          <FormControlLabel value="cc_act" control={<DaiRadio />} label="ACT" />
          <FormControlLabel value="cc_ras" control={<DaiRadio />} label="RAS" />
          <FormControlLabel value="cc_sas" control={<DaiRadio />} label="SAS" />
          <FormControlLabel value="cc_fcs" control={<DaiRadio />} label="FCS" />
          <FormControlLabel value="cc_aim" control={<DaiRadio />} label="AIM" />
          <FormControlLabel value="cc_sec" control={<DaiRadio />} label="SEC" />  
        </RadioGroup>
      )}
    </FormControl>
  );
}
