export const sourceEnum = {
  eu: "source:eTendering",
 fdb: "source:Förderdatenbank",
 bmbf: "source:BMBF",
  een: "source:EEN",
 fdinfo: "source:Förderinfo",
 euRegion: "region:EU",
 bundesweit: "region:bundesweit",
berlin: "region:berlin",

// list of predefined keywords
cc_act:'"driving" "agents" "robot"',
 cc_ras: '"robotics" "artificial intelligence"',
 cc_sas: '"self-adaptive"',
 cc_fcs: '"communication systems" "autonomous"',
 cc_aim: '"intelligence" "learning" "data science" "decision system"',
 cc_sec:
  '"cybersecurity" "security" "vulnerability" "privacy" "trust services"',
}

export const suggestions = ['Medizin', 'Unternehmen', 'Autonomes Fahren', 'Cloud-Computing'];


export function checkSort(params) {
    // Check if search params include sort option
    if (params.get("sort")) {
      if (params.get("sort").includes("start_date")) {
        return "start_date";
      } else if (params.get("sort").includes("end_date")) {
        return "end_date";
      } else {
        return "relevance";
      }
    }
    // if not sort by relevance
    else {
      return "relevance";
    }
  }

  export function formatURLSearchParams(params) {
    // modify URL search params
    const currentURL  = new URL(window.location.origin);
    params.forEach((param) =>
      currentURL.searchParams.append(
        Object.keys(param)[0],
        Object.values(param)[0]
      )
    );
    return currentURL.search;
  }


  
  export function ccToQuery(q) {
    // Loop over each keyword definition in sourceEnum
    Object.keys(sourceEnum).forEach(key => {
        // Check if the key is in the query
        if (q.includes(key)) {
            // Replace the key with its corresponding value from sourceEnum
            q = q.replace(new RegExp(`\\b${key}\\b`, 'g'), sourceEnum[key]);
        }
    });
    return q;
}





export function checkCC(q) {
  // Check if the query contains any CC
  const cc_array = ["cc_act", "cc_ras", "cc_sas", "cc_fcs", "cc_aim", "cc_sec"];
  let cc_value = "none";
  cc_array.forEach(function (cc) {
    if (q.includes(cc)) {
      cc_value = cc;
    }
  });
  return cc_value;
}


export function checkSource(fq) {
  // Return the source value of a given query
  let source_list = {
    all: false,
    eu: false,
    fdb: false,
    bmbf: false,
    een: false,
    fdinfo: false,
  };
  // check if any of the fq parameters include a source
  if (fq.some((item) => item.includes("source:"))) {
    if (fq.some((item) => item.includes(sourceEnum.eu))) {
      source_list.eu = true;
    }
    if (fq.some((item) => item.includes(sourceEnum.fdb))) {
      source_list.fdb = true;
    }
    if (fq.some((item) => item.includes(sourceEnum.bmbf))) {
      source_list.bmbf = true;
    }
    if (fq.some((item) => item.includes(sourceEnum.een))) {
      source_list.een = true;
    }
    if (fq.some((item) => item.includes(sourceEnum.fdinfo))) {
      source_list.fdinfo = true;
    }
  } else {
    source_list = {
      all: true,
      eu: true,
      fdb: true,
      bmbf: true,
      een: true,
      fdinfo: true,
    };
  }
  return source_list;
}

export function checkRegion(fq) {
  // Return the region value of a given query
  let region_list = {
    all: false,
    eu: false,
    bundesweit: false,
    berlin: false,
  };

  // check if any of the fq parameters include a region
  if (fq.some((item) => item.includes("region:"))) {
    if (fq.some((item) => item.includes(sourceEnum.euRegion))) {
      region_list.eu = true;
    }
    if (fq.some((item) => item.includes(sourceEnum.bundesweit))) {
      region_list.bundesweit = true;
    }
    if (fq.some((item) => item.includes(sourceEnum.berlin))) {
      region_list.berlin = true;
    }
  } else {
    region_list = {
      all: true,
      eu: true,
      bundesweit: true,
      berlin: true,
    };
  }
  return region_list;
}


export function convertToFuzzyQuery(query) {
  // Remove all single and double quotes from the query string
  const cleanedQuery = query.replace(/['"]/g, "");

  // Split the query into words, append '~' for fuzzy search, and join back
  const fuzzyQuery = cleanedQuery
    .split(" ")
    .map((word) => word + "~")
    .join(" ");

  return fuzzyQuery;
}

export function containsBooleanOperators(query) {
  // Regular expression to detect Boolean operators
  // It looks for 'AND', 'OR', 'NOT', '&&', '||', and '!'
  // The \b denotes a word boundary, ensuring we don't match words that contain these as substrings
  const booleanOperatorsRegex = /\b(AND|OR|NOT|\&\&|\|\||\!)\b/;

  return booleanOperatorsRegex.test(query);
}

export function checkAuthCookie() {
  if (document.cookie.indexOf("auth_token") > -1) {
    return true;
  } else {
    return false;
  }
}

