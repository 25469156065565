import React from 'react';
import ReactDOM from 'react-dom/client';
import { createBrowserRouter, RouterProvider } from "react-router-dom";
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import Login from './Login';
import SearchResultsPage from './SearchResultsPage';
import { AppProvider } from './Contexts/AppContext';

const AppLayout = ({ children }) => {
  return <AppProvider>{children}</AppProvider>;
};

const router = createBrowserRouter([
  
    {
      path: "/",
      element: <App />,
    },
    {
      path: "/search-results",
      element: <AppLayout><SearchResultsPage /></AppLayout> // Wrap only specific routes
    },
    // children: [
    //   {
    //     path: "login",
    //     element: <Login />,
    //   },
    // ]
]);

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <React.StrictMode>

    <RouterProvider router={router} />

  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
