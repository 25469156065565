import React, { useState } from 'react';
import {
    Box,
  Button,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions
} from '@mui/material';


const Survey = () => {
    
    const [open, setOpen] = useState(false); // Zustand für das Öffnen/Schließen des Dialogs

    const handleOpenSurvey = () => {
      setOpen(true); // Dialog öffnen
    };
    
    const handleClose = () => {
      setOpen(false); // Dialog schließen
    };

    return (
        <>
        <Box sx={{ position: 'fixed', bottom: 16, right: 16 }}>
        <Button sx={{ backgroundColor: '#0C6C81' }} variant="contained" onClick={handleOpenSurvey}>
            Open survey
        </Button>
    </Box>
    <Dialog open={open} onClose={handleClose} fullWidth={true} maxWidth="md">
      <DialogTitle>Survey</DialogTitle>
        <DialogContent>
          {/* Hier könnte der Inhalt Ihrer Umfrage eingefügt werden. */}
          <iframe src="https://docs.google.com/forms/d/e/1FAIpQLScnBbhI1qY2X9WRcM1GMwVaBMhs6lb7GumCgZjkHJ_9YrG9_A/viewform?embedded=true" width="100%" height="500" frameborder="0" marginheight="0" marginwidth="0">Wird geladen…</iframe>
        </DialogContent>
        <DialogActions>
        <Button onClick={handleClose}>Schließen</Button>
        </DialogActions>
    </Dialog>
    </>
    );

};

export default Survey;