import React from 'react';
import { Card, CardActionArea, CardContent, Container, Grid, Typography } from '@mui/material';
import { useFilterContext } from '../Contexts/FilterContext'; // Import the Filter Context

// Example source list
export const source_list = [{
    name: "eTendering", 
    shortName: "eu",
    fullName: "eTendering",
    logo: "etendering_logo.png",
}, {
    name: "Förderdatenbank",
    shortName: "fdb",
    fullName: "Förderdatenbank",
    logo: "foerderdb_logo.png",
}, {
    name: "BMBF",
    shortName: "bmbf",
    fullName: "Bundesministerium für Bildung und Forschung",
    logo: "bmbf_logo.png",
}, {
    name: "EEN",
    shortName: "een",
    fullName: "Enterprise Europe Network",
    logo: "een_logo.png",
}, {
    name: "Förderinfo",
    shortName: "fdinfo",
    fullName: "Förderberatung des Bundes",
    logo: "foerderberatung_logo.jpg",
}];

function Dashboard({ searchFromSearchBar }) {
    const { setFilters, applyFilters, filters } = useFilterContext(); // Access the context

    // When a card is clicked, apply the filter and perform the search
    const handleCardClick = (sourceName) => {
        // 1. Update the filter in the context to ensure it's reflected in the checkbox
        setFilters((prevFilters) => ({
            ...prevFilters,
            sourceValues: {
                ...prevFilters.sourceValues,
                [sourceName]: true, // Automatically apply the clicked source
            },
        }));

        // 2. Apply the filters to ensure the checkbox reflects the selected source
        applyFilters();

        // 3. Perform the search (trigger the searchFromSearchBar method with the source name)
        searchFromSearchBar(`source:${sourceName}`);
    };

    const isLastItem = (index) => {
        return index === source_list.length - 1;
    };

    return (
        <Container sx={{ paddingTop: '100px' }}>
            <Typography 
                variant="h5" 
                align="center" 
                sx={{ marginBottom: '40px', fontWeight: 'bold', color: '#0C6C81' }}
            >
                Search for Institution:
            </Typography>
            <Grid container spacing={2}>
                {source_list.map((source, index) => (
                    <Grid 
                        item xs={12} sm={6} md={4} 
                        key={source.shortName} 
                        sx={{ marginBottom: isLastItem(index) ? '64px' : '8px' }}
                    >
                        <Card sx={{ display: 'flex', flexDirection: 'column' }}>
                            <CardActionArea onClick={() => handleCardClick(source.name)}>
                                <CardContent>
                                    <div className='source-image'>
                                        <img src={source.logo} alt='Source Logo'></img>
                                    </div>
                                    <div className='source-fullName'>
                                        <Typography variant='body1'>
                                            <b>{source.fullName}</b>
                                        </Typography>
                                    </div>
                                    <div className='source-stats'>
                                        <Typography variant='caption' sx={{ color: '#312783' }}>
                                            <b>Number of Documents Indexed: </b> {source.numDocs}
                                        </Typography>
                                        <Typography variant='caption' sx={{ color: '#312783' }}>
                                            <b>Number of Documents Released Past Week: </b> {source.numDocsLastWeek}
                                        </Typography>
                                        <Typography variant='caption' sx={{ color: '#312783' }}>
                                            <b>Last Crawled on: </b> {source.lastCrawled}
                                        </Typography>
                                    </div>
                                </CardContent>
                            </CardActionArea>
                        </Card>
                    </Grid>
                ))}
            </Grid>
        </Container>
    );
}

export default Dashboard;
