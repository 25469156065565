import React, { useRef } from 'react';
import {
    Typography,
    Button,
    Box
} from "@mui/material";
import { Container } from "@mui/system";
import ResponsiveAppBar from '../components/ResponsiveAppBar';
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import { useAppContext } from '../Contexts/AppContext';
import SearchBar from '../components/SearchBar';

function FirstPage() {
    const { searchBarInput, setSearchBarInput, searchFromSearchBar, handleLoginShow, isAuth } = useAppContext();

    // Reference for the target element to scroll to
    const targetRef = useRef(null);

    const scrollToNextSection = () => {
        if (targetRef.current) {
            targetRef.current.scrollIntoView({ behavior: 'smooth' });
        }
    };

    return (
        <>
            <div className="HomePage">
                <div className="AppBar">
                    <ResponsiveAppBar
                        sx={{ minHeight: '300vh' }} 
                        searchBarInput={searchBarInput}
                        setSearchBarInput={setSearchBarInput}
                        searchFromSearchBar={searchFromSearchBar}
                        isFirstPage={true}
                        handleLoginShow={handleLoginShow}
                        isAuth={isAuth}
                    />
                </div>

                <Container
                    component="main"
                    maxWidth="sm"
                    sx={{
                        textAlign: "center",
                        padding: "2em",
                        backgroundColor: "0C6C81",
                    }}
                >
                    <Box sx={{ paddingTop: '140px' }}>
                        <img
                            src="PIA_logo.png"
                            alt="PIA logo"
                            width={100}
                            style={{
                                width: "100px",
                                height: "100px",
                                marginBottom: "2rem",
                            }}
                        />
                        <Typography
                            variant="h5"
                            component="h1"
                            gutterBottom
                            color="#0C6C81"
                            sx={{
                                fontSize: "1.5rem",
                                fontWeight: "normal",
                                marginBottom: "2rem",
                            }}
                        >
                            Personal Information Assistant 
                        </Typography>

                        <SearchBar
                            searchBarInput={searchBarInput}
                            setSearchBarInput={setSearchBarInput}
                            searchFromSearchBar={searchFromSearchBar}
                            isFirstPage={true}
                        />

                        <Typography
                            variant="body1"
                            color="textSecondary"
                            sx={{ marginTop: "2rem" }}
                        >
                            Your database for research and funding calls
                        </Typography>
                    </Box>

                    {/* This is the target element to scroll to */}
                    <div ref={targetRef}></div>

                    <Button
                        onClick={scrollToNextSection} // Scrolls the page down on click
                        sx={{
                            marginTop: "2rem",
                            fontSize: "40px",
                            position: "relative",
                            display: "block",
                            left: "50%",
                            transform: "translateX(-50%)",
                        }}
                    >
                        <KeyboardArrowDownIcon sx={{ color: '#206B80' }} />
                        </Button>
                </Container>
            </div>
        </>
    );
}

export default FirstPage;
