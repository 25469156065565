import React, { useState } from 'react';
import FormControlLabel from '@mui/material/FormControlLabel';
import FormControl from '@mui/material/FormControl';
import DaiCheckBox from '../DaiCheckBox';
import { FormGroup, Tooltip, Button, Box } from '@mui/material';
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import KeyboardArrowUpIcon from "@mui/icons-material/KeyboardArrowUp";
import { source_list } from '../components/Dashboard';
import { useFilterContext } from '../Contexts/FilterContext';

export default function SourcesCheckBoxesGroup() {
  const [open, setOpen] = useState(true);

  const { filters, handleSource, setFilters } = useFilterContext();

  const toggleRadioGroup = () => {
    setOpen(!open);
  };

  const handleSelectAll = (event) => {
    const isChecked = event.target.checked;

    if (isChecked) {
      // If "Select All" is checked, remove all filters (user wants to see all sources)
      setFilters(prev => ({
        ...prev,
        sourceValues: {}, // An empty object represents no filtering
      }));
    } else {
      // If unchecking "Select All", reset the filters to none selected (optional behavior)
      const newSourceValues = {};
      source_list.forEach(source => {
        newSourceValues[source.name] = false;
      });
      setFilters(prev => ({
        ...prev,
        sourceValues: newSourceValues,
      }));
    }
  };

  const clearSelection = () => {
    const newSourceValues = {};
    source_list.forEach(source => {
      newSourceValues[source.name] = false;
    });

    setFilters(prev => ({
      ...prev,
      sourceValues: newSourceValues,
    }));
  };

  const isAllSelected = Object.values(filters.sourceValues).every(val => val === true);

  return (
    <FormControl sx={{ 
        textAlign: 'left',
        paddingRight: '1.8rem',
        paddingLeft: '1.8rem',
        paddingTop: '0.5em',
        backgroundColor: open ? '#F5F5F5' : 'transparent',
        boxShadow: open ? 3 : 'none',
        borderRadius: 2,
        overflow: 'hidden'
    }}>  
      <Button
        variant="contained"
        endIcon={open ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
        onClick={toggleRadioGroup}
        sx={{
          backgroundColor: '#0C6C81',
          color: '#ffffff',
          '&:hover': { backgroundColor: '#0a5968' },
          borderRadius: '20px',
          textTransform: 'none',
          boxShadow: 'none',
          fontSize: '1rem',
          fontWeight: 'bold',
          padding: '10px 80px',
        }}
      >
        Sources 
      </Button>

      {open && (
        <Box sx={{ 
            p: 2, 
            backgroundColor: '#F5F5F5',
            borderTop: '1px solid #E0E0E0'
        }}>
          <Box sx={{
              display: 'flex',
              justifyContent: 'space-between',
              alignItems: 'center',
              mb: 2,
          }}>
            <FormControlLabel
              control={<DaiCheckBox checked={isAllSelected} onChange={handleSelectAll} name='all' />}
              label="Select All"
              sx={{
                  '.MuiFormControlLabel-label': {
                  fontWeight: 'bold',
                  },
              }}
            />
            <Button variant="text" onClick={clearSelection} sx={{ textTransform: 'none', color: '#0C6C81' }}>
              Clear
            </Button>
          </Box>
                
          <FormGroup sx={{ 
              p: 2, 
              backgroundColor: '#F5F5F5',
              borderTop: '1px solid #E0E0E0'
          }}>
            {source_list.map((source) => (
              <Tooltip key={source.fullName} title={source.fullName} placement='left'>
                <FormControlLabel
                  control={<DaiCheckBox checked={filters.sourceValues[source.name] || false} onChange={handleSource} name={source.name} />}
                  label={source.name}
                  sx={{
                      mb: 1,
                      '.MuiFormControlLabel-label': {
                      color: '#424242',
                      },
                  }}
                />
              </Tooltip>
            ))}
          </FormGroup>
        </Box>
      )}
    </FormControl>
  );
}
