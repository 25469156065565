import React, { useState } from 'react';
import FormControlLabel from '@mui/material/FormControlLabel';
import FormControl from '@mui/material/FormControl';
import DaiCheckBox from '../DaiCheckBox';
import { FormGroup, Button, Box } from '@mui/material';
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import KeyboardArrowUpIcon from "@mui/icons-material/KeyboardArrowUp";
import { useFilterContext } from '../Contexts/FilterContext';

const region_list = [
  { name: "EU-wide", key: "eu" },
  { name: "Bundesweit", key: "bundesweit" },
  { name: "Berlin only", key: "berlin" }
];

export default function RegionsCheckBoxesGroup() {
  const [open, setOpen] = useState(true);

  const { filters, handleRegion, setFilters } = useFilterContext();

  const toggleGroup = () => {
    setOpen(!open);
  };

  // Handle "Select All" logic: no filter is applied, but individual checkboxes are not checked
  const handleSelectAll = (event) => {
    const isChecked = event.target.checked;

    if (isChecked) {
      // If "Select All" is checked, we clear all individual region filters (show all)
      setFilters(prev => ({
        ...prev,
        regionValues: {}, // Clear all region filters
      }));
    } else {
      // If "Select All" is unchecked, reset all individual checkboxes to false
      const newRegionValues = {};
      region_list.forEach(region => {
        newRegionValues[region.key] = false;
      });

      setFilters(prev => ({
        ...prev,
        regionValues: newRegionValues,
      }));
    }
  };

  const clearSelection = () => {
    const newRegionValues = {};
    region_list.forEach(region => {
      newRegionValues[region.key] = false;
    });

    setFilters(prev => ({
      ...prev,
      regionValues: newRegionValues,
    }));
  };

  // Check if no region filters are applied (i.e., "Select All" is selected)
  const isAllSelected = Object.keys(filters.regionValues).length === 0;

  return (
    <FormControl sx={{
      textAlign: 'left',
      paddingRight: '1.8rem',
      paddingLeft: '1.8rem',
      paddingTop: '0.5em',
      backgroundColor: open ? '#F5F5F5' : 'transparent',
      boxShadow: open ? 3 : 'none',
      borderRadius: 2,
      overflow: 'hidden'
    }}>
      <Button
        variant="contained"
        endIcon={open ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
        onClick={toggleGroup}
        sx={{
          backgroundColor: '#0C6C81',
          color: '#ffffff',
          '&:hover': { backgroundColor: '#0a5968' },
          borderRadius: '20px',
          textTransform: 'none',
          boxShadow: 'none',
          fontSize: '1rem',
          fontWeight: 'bold',
          padding: '10px 80px',
        }}
      >
        Regions
      </Button>

      {open && (
        <Box sx={{
          p: 2,
          backgroundColor: '#F5F5F5',
          borderTop: '1px solid #E0E0E0',
        }}>
          <FormGroup>
            <Box sx={{
              display: 'flex',
              justifyContent: 'space-between',
              alignItems: 'center',
              mb: 2,
            }}>
              <FormControlLabel
                control={<DaiCheckBox checked={isAllSelected} onChange={handleSelectAll} name='all' />}
                label="Select All"
                sx={{
                  '.MuiFormControlLabel-label': { 
                    fontWeight: 'bold',
                  },
                }}
              />
              <Button variant="text" onClick={clearSelection} sx={{ textTransform: 'none', color: '#0C6C81' }}>
                Clear
              </Button>
            </Box>
            {region_list.map(region => (
              <FormControlLabel
                key={region.key}
                control={<DaiCheckBox checked={filters.regionValues[region.key] || false} onChange={handleRegion} name={region.key} />}
                label={region.name}
              />
            ))}
          </FormGroup>
        </Box>
      )}
    </FormControl>
  );
}
