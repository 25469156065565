import React, { useState, useRef } from 'react';
import SearchBar from './SearchBar';
import FilterPane from '../FiltersGroup/FilterPane';
import AppBar from '@mui/material/AppBar';
import Box from '@mui/material/Box';
import TuneIcon from '@mui/icons-material/Tune';
import CloseIcon from '@mui/icons-material/Close';
import Toolbar from '@mui/material/Toolbar';
import IconButton from '@mui/material/IconButton';
import Container from '@mui/material/Container';
import Button from '@mui/material/Button';
import Tooltip from '@mui/material/Tooltip';
import { AccountCircle } from '@mui/icons-material';
import { useAppContext } from '../Contexts/AppContext';
import { FilterProvider } from '../Contexts/FilterContext';
import { suggestions } from "../utils.js";
import SearchIcon from '@mui/icons-material/Search';
import '../App.css';
import Login from '../Login.js';

function ResponsiveAppBar({ isFirstPage, searchBarInput, setSearchBarInput }) {
  const { ccFilterValue, searchFromSearchBar, saveSearch } = useAppContext();
  const [anchorElFilter, setAnchorElFilter] = useState(null);
  const [showSuggestions, setShowSuggestions] = useState(false);
  const [showLogin, setShowLogin] = useState(false); // State to manage login visibility

  const toggleFilterPaneVisibility = (event) => {
    if (anchorElFilter) {
      setAnchorElFilter(null); // Close if already open
    } else {
      setAnchorElFilter(event.currentTarget); // Open the filter panel
    }
  };

  const handleSearchFocus = () => {
    setShowSuggestions(true);
    setAnchorElFilter(null); // Close the filter panel if the search bar is focused
  };

  const handleClose = () => {
    setShowSuggestions(false);
  };

  const handleSearchBlur = () => {
    setTimeout(() => {
      setShowSuggestions(false);
    }, 100);
  };

  const searchBarRef = useRef(null);

  const handleSaveSearch = () => {
    if (searchBarInput.trim()) {
      saveSearch(searchBarInput);
    }
  };

  const handleLoginOpen = () => {
    setShowLogin(true); // Open the login
  };

  const handleLoginClose = () => {
    setShowLogin(false); // Close the login
  };

  return (
    <AppBar position="fixed" sx={{ backgroundColor: isFirstPage ? 'white' : '#EDEDED' }}>
      <Container maxWidth="xl" sx={{ px: { xs: 0, sm: 2 } }}>
        <Toolbar disableGutters sx={{ display: 'flex', justifyContent: 'center' }}>
          {!isFirstPage && (
            <>
              <Button onClick={handleLoginClose} sx={{ color: 'white', display: 'flex', justifyContent: 'center', textTransform: 'none' }}>
                <a href={window.location.origin + `/?q=&page=1`} style={{ display: 'flex', justifyContent: 'left', alignItems: 'left', height: '100%', width: '100%' }}>
                  <img src="PIA_logo.png" alt="PIA logo" style={{ maxHeight: '40px' }}/>
                </a>
              </Button>

              <Box sx={{ flexGrow: 1, display: 'flex', justifyContent: 'center', alignItems: 'center', marginX: 'auto', maxWidth: {
                xs: '100%',  // Full width on the smallest screens
                sm: isFirstPage ? '700px' : '900px',
              }}}>
                <SearchBar
                  searchBarInput={searchBarInput}
                  setSearchBarInput={setSearchBarInput}
                  onFocus={handleSearchFocus}
                  noFocus={handleClose}
                  onBlur={handleSearchBlur}
                  ref={searchBarRef}
                />
                <Box className="searchBox">
                  {showSuggestions && suggestions.map((s, index) => (
                    <Box
                      key={index}
                      className="suggestion-item"
                      onMouseDown={() => {
                        setSearchBarInput(s);
                        searchFromSearchBar(s);
                        setShowSuggestions(false);
                        setAnchorElFilter(null); // Close filter panel when suggestion is clicked
                      }}
                    >
                      <SearchIcon className="suggestion-icon"/>
                      {s}
                    </Box>
                  ))}
                </Box>
                <Button
                  sx={{ color: '#036b80', fontWeight: 'bold', display: { xs: 'none', sm: 'inline-flex' } }}
                  endIcon={anchorElFilter ? <CloseIcon /> : <TuneIcon />}
                  onClick={toggleFilterPaneVisibility}
                >
                </Button>
                <FilterProvider>
                  <FilterPane
                    open={Boolean(anchorElFilter)}
                    anchorEl={anchorElFilter}
                    onClose={() => setAnchorElFilter(null)}
                    ccValue={ccFilterValue}
                  />
                </FilterProvider>
              </Box>
            </>
          )}
            {!isFirstPage && (
              <Tooltip title="User Management">
                <IconButton onClick={handleLoginOpen} sx={{ justifyContent: 'left', alignItems: 'left' }}>
                  <AccountCircle sx={{ fontSize: 'xxx-large' }} />
                </IconButton>
              </Tooltip>
            )}
        
        </Toolbar>
      </Container>

      {/* Conditionally render the Login component only if we're not on the first page */}
      {showLogin && (
        <Login handleLoginClose={handleLoginClose} setIsAuth={(authStatus) => console.log('Auth status:', authStatus)} />
      )}
    </AppBar>
  );
}

export default ResponsiveAppBar;
