import React, { useEffect, useState } from 'react';
import { Container, Grid, CircularProgress, Typography, Stack, Pagination, Box, Button, Link } from '@mui/material';
import SearchResult from './SearchResult';
import { useAppContext } from './Contexts/AppContext';
import FilterPane from './FiltersGroup/FilterPane';
import ResponsiveAppBar from './components/ResponsiveAppBar';
import { useSearchParams, useNavigate, useLocation } from 'react-router-dom';
import { FilterProvider } from './Contexts/FilterContext';
import Survey from './components/Survey';
import SortByRadioButtonsGroup from './FiltersGroup/SortByRadioButtonsGroup';
import TuneIcon from '@mui/icons-material/Tune';
import CloseIcon from '@mui/icons-material/Close';
import SavedSearchesList from './SavedSearchList';

const rowsPerPage = 10;

const SearchResultsPage = () => {
  const { docs, numTotalDocs, setDocs, setNumTotalDocs, sortByValue, setSortByValue, searchDocs } = useAppContext();
  const [searchParams, setSearchParams] = useSearchParams();
  const [searchBarInput, setSearchBarInput] = useState(searchParams.get('q') || '');
  const [isLoading, setIsLoading] = useState(true);
  const [error, setError] = useState('');
  const [page, setPage] = useState(parseInt(searchParams.get('page')) || 1);
  const navigate = useNavigate();
  const location = useLocation();

  // Mock suggestions (replace with actual data if available)
  const suggestions = ['Förderung', 'EU', 'Autonomes Fahren'];

  // Fetch search results based on URL changes
  useEffect(() => {
    const query = searchParams.get('q') || '*:*';
    const sortBy = searchParams.get('sort') || 'relevance';
    const currentPage = parseInt(searchParams.get('page')) || 1;

    setIsLoading(true);

    // Ensure filterQueries is always an array
    const fqParams = searchParams.getAll('fq') || [];

    // Fetch the documents
    searchDocs(query, fqParams, sortBy, (currentPage - 1) * rowsPerPage)
      .then(() => {
        setIsLoading(false);
        setPage(currentPage);  // Ensure page is synced after fetch
      })
      .catch((err) => {
        setError(err.message);
        setIsLoading(false);
      });
  }, [location]);  // Depend on location to react to URL changes

  // Handle sorting change
  const handleSortBy = (event) => {
    const newSortValue = event.target.value;
    setSortByValue(newSortValue);

    // Clone existing searchParams
    const newParams = new URLSearchParams(searchParams);

    // Set the new sort value while keeping other parameters
    newParams.set('sort', newSortValue);

    // Update the search parameters without overwriting the rest
    navigate({
      pathname: '/search-results',
      search: newParams.toString(),
    });
  };

  const [anchorElFilter, setAnchorElFilter] = useState(null);

  const handleCloseFilterMenu = () => {
    setAnchorElFilter(null);
  };

  const toggleFilterPaneVisibility = (event) => {
    if (!anchorElFilter) {
      setAnchorElFilter(event.currentTarget);
    } else {
      setAnchorElFilter(null);
    }
  };

  const searchFromSearchBar = (q) => {
    setSearchBarInput(q);  // Update search input in the search bar
    navigate({
      pathname: '/search-results',
      search: `?q=${encodeURIComponent(q)}&page=1`,
    });
  };

  // Handle page change and URL update
  const handlePageChange = (event, value) => {
    // Clone the current search params
    const newParams = new URLSearchParams(searchParams);

    // Update the 'page' parameter
    newParams.set('page', value);

    // Update the URL to reflect the new page number
    navigate({
      pathname: '/search-results',
      search: newParams.toString(),
    });

    setPage(value);  // Update local page state immediately to ensure re-render
  };

  // Define the handleNavigate function for search suggestions
  const handleNavigate = (suggestion) => {
    setSearchBarInput(suggestion);  // Update search bar input for the suggestion
    searchFromSearchBar(suggestion);
  };

  return (
    <Box sx={{ display: 'flex', flexDirection: 'column', minHeight: '100vh' }}>
      <ResponsiveAppBar
        searchBarInput={searchBarInput}
        setSearchBarInput={setSearchBarInput}
        searchFromSearchBar={searchFromSearchBar}
        isFirstPage={false}
      />
      <SavedSearchesList />

      <div className="Search" style={{ flexGrow: 1, overflowY: 'auto' }}>
        <div className="Progress" style={isLoading ? { display: 'block' } : { display: 'none' }}>
          <Container>
            <CircularProgress sx={{ color: '#036b80' }} />
          </Container>
        </div>

        {numTotalDocs > 0 && !isLoading ? (
          <>
            <div className="Pagination" style={{ paddingBottom: '20px' }}>
              <Container>
                <Stack spacing={2} alignItems="center">
                  <Pagination
                    showFirstButton
                    showLastButton
                    page={page} // Page state is directly passed here
                    count={Math.ceil(numTotalDocs / rowsPerPage)} // Number of pages
                    onChange={handlePageChange} 
                    size="small"
                    shape="rounded"
                    sx={{
                      marginTop: '20px',
                      marginBottom: '20px',
                      button: {
                        color: "#036b80",
                        "&.Mui-selected": {
                          backgroundColor: "#036b80",
                          color: "white",
                        },
                      },
                      div: {
                        color: "#036b80",
                      },
                    }}
                  />
                  <Survey />
                </Stack>

                <Grid container justifyContent="space-between" spacing={2} alignItems="center">
                  <Grid item sx={{ paddingBottom: '10px' }}>
                    <SortByRadioButtonsGroup value={sortByValue} handleClick={handleSortBy} />
                  </Grid>

                  <Button
                    sx={{
                      color: '#036b80',
                      fontWeight: 'bold',
                      display: { xs: 'inline-flex', sm: 'none' }
                    }}
                    endIcon={anchorElFilter ? <CloseIcon /> : <TuneIcon />}
                    onClick={toggleFilterPaneVisibility}
                  />
                  <FilterProvider>
                    <FilterPane
                      open={Boolean(anchorElFilter)}
                      anchorEl={anchorElFilter}
                      onClose={handleCloseFilterMenu}
                    />
                  </FilterProvider>

                  <Grid item>
                    <Typography variant="subtitle2" sx={{ paddingBottom: '16px', alignItems:'center'}}>
                      <b>{numTotalDocs.toLocaleString("de")}</b> results found in total
                    </Typography>
                  </Grid>
                </Grid>
              </Container>
            </div>

            <div className="Results">
              <Container>
                <Grid container spacing={2} alignItems="center">
                  {docs.map((doc) => (
                    <Grid item xs={12} key={doc.id}>
                      <SearchResult doc={doc} />
                    </Grid>
                  ))}
                </Grid>
              </Container>
            </div>
          </>
        ) : (
          <Typography variant="h6" style={{ textAlign: 'center', marginTop: '130px' }}>
            <div sx={{ paddingBottom: '80px' }}>
              <img width={'60px'} src='../NO_SEARCH.png' alt="No search results" />
            </div>
            {error ? error : 'No results found. Try searching for: '}
            <br />
            {suggestions.map((suggestion, index) => (
              <React.Fragment key={index}>
                <Link href="#" onClick={() => handleNavigate(suggestion)} style={{ margin: '0 5px' }}>
                  {suggestion}
                </Link>
                {(index < suggestions.length - 1) ? ' | ' : ''}
              </React.Fragment>
            ))}
          </Typography>
        )}
      </div>
    </Box>
  );
};

export default SearchResultsPage;
