import React, { useState, useEffect } from 'react';
import { Paper, InputBase, IconButton, styled } from '@mui/material';
import SearchIcon from '@mui/icons-material/Search';
import MicIcon from '@mui/icons-material/Mic';
import StarBorderIcon from '@mui/icons-material/StarBorder';
import StarIcon from '@mui/icons-material/Star';
import { useSpeechRecognition } from 'react-speech-recognition';
import { useAppContext } from '../Contexts/AppContext';
import SpeechRecognition from 'react-speech-recognition';
import { useLocation } from 'react-router-dom';

// Styles for the SearchBar when on the first page
const FirstPageStyledPaper = styled(Paper)({
  display: 'flex',
  alignItems: 'center',
  padding: '2px 4px',
  margin: 'auto',
  maxWidth: '800px',
  borderRadius: '20px',
  boxShadow: '0 3px 5px 2px rgba(0, 0, 0, .05)',
});

// Styles for the SearchBar when not on the first page
const OtherPageStyledPaper = styled(Paper)({
  display: 'flex',
  alignItems: 'center',
  padding: '2px 4px',
  maxWidth: '800px',
  borderRadius: '20px',
  boxShadow: '0 3px 5px 2px rgba(0, 0, 0, .05)',
});

const StyledInputBase = styled(InputBase)({
  flex: 1,
  '& .MuiInputBase-input': {
    '@media (min-width: 600px)': {
      padding: '10px 20px',
    },
    '@media (max-width: 599px)': {
    },
    width: '100%',
  },
});

const SearchIconButton = styled(IconButton)({
  padding: '10px',
});

const MicIconButton = styled(IconButton)({
  padding: '10px',
});

function SearchBar({ isFirstPage, onFocus, noFocus, onBlur }) {
  const location = useLocation(); // Get the current location
  const isSearchResultsPage = location.pathname === '/search-results'; // Adjust this path to your actual search results route

  const { searchBarInput, setSearchBarInput, searchFromSearchBar, saveSearch, savedSearches, setSavedSearches } = useAppContext();
  const [isFocused, setIsFocused] = useState(false);
  const [isSaved, setIsSaved] = useState(savedSearches.includes(searchBarInput));
  const [isMicActive, setIsMicActive] = useState(false);

  const {
    transcript,
    listening,
    resetTranscript,
    browserSupportsSpeechRecognition,
  } = useSpeechRecognition();

  useEffect(() => {
    if (!listening && transcript) {
      setSearchBarInput(transcript);
      searchFromSearchBar(transcript);
    }
  }, [listening, transcript]);

  useEffect(() => {
    setIsSaved(savedSearches.includes(searchBarInput));
  }, [searchBarInput, savedSearches]);

  if (!browserSupportsSpeechRecognition) {
    return <span>Browser doesn't support speech recognition.</span>;
  }

  const handleMicClick = () => {
    if (isMicActive) {
      SpeechRecognition.stopListening();
    } else {
      SpeechRecognition.startListening();
    }
    setIsMicActive(!isMicActive);
  };

  const handleSaveSearch = () => {
    if (isSaved) {
      setSavedSearches(savedSearches.filter(search => search !== searchBarInput));
    } else {
      saveSearch(searchBarInput);
    }
    setIsSaved(!isSaved);
  };

  const CustomStyledPaper = isFirstPage ? FirstPageStyledPaper : OtherPageStyledPaper;

  return (
    <CustomStyledPaper component="form">
      <SearchIconButton aria-label="search" onClick={() => searchFromSearchBar(searchBarInput)}>
        <SearchIcon />
      </SearchIconButton>
      <StyledInputBase
        placeholder="search database (e.g., 'Bund') ..."
        value={searchBarInput}
        onChange={(e) => setSearchBarInput(e.target.value)}
        onKeyDown={(e) => {
          if (e.key === 'Enter') {
            searchFromSearchBar(searchBarInput);
            noFocus();
            e.preventDefault();
          }
        }}
        onFocus={() => { setIsFocused(true); if (onFocus) onFocus(); }}
        onBlur={onBlur}
        inputProps={{ 'aria-label': 'search' }}
      />
      <>
        <MicIconButton aria-label="microphone" onClick={handleMicClick}>
          <MicIcon sx={{ color: isMicActive ? '#206B80' : 'inherit' }} />
        </MicIconButton>
        {isSearchResultsPage && ( // Conditionally render the star icon only on the search results page
          <IconButton
            sx={{ color: '#FFD700' }}
            onClick={handleSaveSearch}
            aria-label="save search"
          >
            {isSaved ? <StarIcon /> : <StarBorderIcon />}
          </IconButton>
        )}
      </>
    </CustomStyledPaper>
  );
}

export default SearchBar;
