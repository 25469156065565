import React, { useEffect, useState } from 'react';
import './App.css';
import { Button, Card, CardActions, CardContent, Link, Typography } from '@mui/material';
import parseHTML from 'html-react-parser';
import { source_list } from './components/Dashboard';


function getDisplayItems(doc) {
  // Return appropriate title, description fields and the logo based on the source website
  if (doc.url.includes('foerderdatenbank.de')) {
    return [doc.title_de, doc.description_de, 'foerderdb_logo.png'];
  }
  else if (doc.url.includes('europa.eu')) {
    return [doc.title_en, doc.description_en, 'etendering_logo.png'];
  }
  else if (doc.url.includes('interaktive-technologien.de')) {
    return [doc.title_de, doc.description_de, 'bmbf_logo.png'];
  }
  else if (doc.url.includes('een-bb.de')) {
    return [doc.title_de, doc.description_de, 'een_logo.png'];
  }
  else {
    return [doc.title_de, doc.description_de, 'foerderberatung_logo.jpg'];
  }

  // // Return appropriate logo based on the source website
  // const source = source_list.find(source => source.name === doc.source);
  // if (source) {
  //   return source.logo;
  // }
  // // Unknown source
  // else {
  //   return 'PIA_logo.png';
  // }

}

// function getRegions(doc) {
//   if (doc.region.length > 1) {
//     doc.region.map((rgn) => (  // if there are more than 0 results
//       return rgn + ", "
//     )
//   }
// }

const SearchResult = ({ doc }) => {

  const [expanded, setExpanded] = useState(false);
  const startDate = new Date(doc.start_date);
  const endDate = new Date(doc.end_date);
  const [title, description, logo_source] = getDisplayItems(doc);

  return (
    <Card sx={{ minWidth: 275 }}>
      <CardContent sx={{padding: '16px 16px 0px 16px'}}>
        <div className='result-top'>
          <div className='result-header'>
            <div className='result-title'>
              <Link href={doc.url} variant="p" target="_blank" rel="noopener noreferrer" sx={{ color: '#036b80' }}>
                {title}
              </Link>
            </div>
            <div className='result-meta'>
              <Typography variant='caption'>
                <b>Start Date: </b> {startDate.toLocaleDateString('de-DE')}
              </Typography>
              <Typography variant='caption'>
                <b>Due Date: </b> {endDate.toLocaleDateString('de-DE')}
              </Typography>
            </div>
            <div className='result-meta'>
              <Typography variant='caption'>
                <b>Region(s): </b> {
                  doc.region?.length > 0
                    ? doc.region.join(', ')
                    : (  // else
                      'Not available'
                    )}
              </Typography>
            </div>
          </div>
          <div className='result-image'>
            <img src={logo_source} alt='Source Logo'></img>
          </div>
        </div>
        <div className='result-content'>
          <Typography variant="caption" component="div"
            // change the size of the text based on the state of 'expanded'
            sx={expanded ? { textAlign: 'left', minHeight: '80px' } : { textAlign: 'left', height: '80px', overflowY: 'clip' }}>
            {
              (!description || description === '') // if content is missing
                ? <i>Content is not available</i>  // render this
                : <div className='description-text'>
                  {parseHTML(description)}
                </div>// else this
            }
          </Typography>
        </div>
      </CardContent>
      <CardActions>
        <Button size="small" onClick={() => setExpanded(!expanded)}>
          {
            expanded ? 'Show Less' : 'Expand'
          }
        </Button>
      </CardActions>
    </Card>
  );
}

export default SearchResult;