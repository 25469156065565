import React from 'react';
import { Box, Typography, Fab } from '@mui/material';
import { useNavigate } from 'react-router-dom';

const AwarenessPage = () => {
  const navigate = useNavigate();

  const handleNavigate = (query) => {
    navigate(`/search-results?q=${encodeURIComponent(query)}`);
  };

  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: { xs: 'column', sm: 'row' }, // Stack items vertically on xs (mobile) and row on sm+
        height: '100vh', // Full viewport height
        bgcolor: '#3D95A1', // Set the background color for the whole page
        p: { xs: 2, sm: 0 }, // Padding on mobile to avoid edge clipping
      }}
    >
      {/* Right half with text and buttons */}
      <Box
        sx={{
          flex: 1,
          display: 'flex',
          flexDirection: 'column',
          justifyContent: { xs: 'center', sm: 'center' }, // Top alignment on mobile, center on desktop
          alignItems: { xs: 'flex-start', sm: 'flex-end' }, // Left alignment on mobile, right align on desktop
          padding: { xs: '1rem', sm: '0 4rem' }, // Adjust padding for mobile
          mt: { xs: 4, sm: 0 }, // Add more top margin on mobile
          color: 'white',
          textAlign: { xs: 'left', sm: 'right' }, // Left text alignment on mobile, right on desktop
          width: { xs: '100%', sm: '50%' }, // Full width on mobile, half width on desktop
        }}
      >
        <Typography
          variant="h4"
          gutterBottom
          sx={{
            fontWeight: 'bold',
            fontSize: { xs: '1.5rem', sm: '2.125rem' }, // Adjust font size on mobile
          }}
        >
          Discover New Content Relevant to Your Organization
        </Typography>
        <Typography
          variant="body1"
          gutterBottom
          sx={{
            marginBottom: '2rem',
            fontSize: { xs: '1rem', sm: '1.25rem' }, // Adjust font size on mobile
            lineHeight: { xs: 1.5, sm: 1.75 }, // Better line spacing on mobile
          }}
        >
          Explore a wide range of content tailored to your institution with our powerful search engine. Uncover the latest updates, research, and information that matter to you most. Start your search now and stay ahead with the knowledge you need.
        </Typography>

        <Box
          sx={{
            display: 'flex',
            flexDirection: 'row',
            flexWrap: 'wrap', // Allow buttons to wrap if necessary
            gap: 1, // Space between buttons
            justifyContent: { xs: 'flex-start', sm: 'flex-end' }, // Align right on desktop, start on mobile
            width: '100%',
          }}
        >
          <Fab
            variant="extended"
            onClick={() => handleNavigate('Förderung')}
            sx={{
              backgroundColor: 'black',
              borderRadius: '20px',
              minHeight: '50px',
              minWidth: '150px',
            }}
          >
            <Typography sx={{ color: 'white', fontWeight: 'bold' }}>Förderung</Typography>
          </Fab>

          <Fab
            variant="extended"
            onClick={() => handleNavigate('Gesundheit')}
            sx={{
              backgroundColor: 'white',
              borderRadius: '20px',
              minHeight: '50px',
              minWidth: '100px',
            }}
          >
            <Typography sx={{ fontWeight: 'bold' }}>Gesundheit</Typography>
          </Fab>
          <Fab
            variant="extended"
            onClick={() => handleNavigate('EU')}
            sx={{
              backgroundColor: 'white',
              borderRadius: '20px',
              minHeight: '50px',
              minWidth: '100px',
            }}
          >
            <Typography sx={{ fontWeight: 'bold' }}>EU</Typography>
          </Fab>
          <Fab
            variant="extended"
            onClick={() => handleNavigate('Forschung')}
            sx={{
              backgroundColor: 'white',
              borderRadius: '20px',
              minHeight: '50px',
              minWidth: '100px',
            }}
          >
            <Typography sx={{ fontWeight: 'bold' }}>Forschung</Typography>
          </Fab>
        </Box>
      </Box>

      {/* Left half with the magnifying glass image, hidden on mobile */}
      <Box
        sx={{
          width: { xs: '100%', sm: '50%' }, // Full width on mobile, 50% on desktop
          display: { xs: 'none', sm: 'flex' }, // Hide image on mobile, show on desktop
          justifyContent: 'center',
          alignItems: 'center',
        }}
      >
        <img src="/Lupe.png" alt="Lupe" style={{ width: '60%' }} />
      </Box>
    </Box>
  );
};

export default AwarenessPage;
