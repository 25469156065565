import React from "react"
import { AppProvider } from "../Contexts/AppContext"
import SecondPage from "./SecondPage";
import ThirdPage from "./ThirdPage";
import FirstPage from "./FirstPage";
import { FilterProvider } from "../Contexts/FilterContext";

const HorizontalContantainer = () => {
    return (
     <div className="horizontalScrollContainer">

        <AppProvider>
         <FirstPage></FirstPage>

            <FilterProvider>
            <SecondPage></SecondPage>

            </FilterProvider>

            <ThirdPage></ThirdPage>
        </AppProvider>
     

    </div>
    );
};
export default HorizontalContantainer;