import React from 'react';
import Radio from '@mui/material/Radio';


export default function DaiRadio(props) {
    // Custom Radio Button Design for PIA
    return (
        <Radio
          {...props}          
          sx={{
            color: "#036b80",
            '&.Mui-checked': {
                color: "#036b80",
            },
          }}
        />
      );
}