import React from 'react';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Drawer from '@mui/material/Drawer';
import CCRadioButtonsGroup from './CCRadioButtonsGroup';
import SourcesCheckBoxesGroup from './SourcesCheckBoxesGroup';
import RegionsCheckBoxesGroup from './RegionsCheckBoxesGroup';
import { useTheme } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';
import { useFilterContext } from '../Contexts/FilterContext';
import IconButton from '@mui/material/IconButton';
import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos';

export default function FilterPane({ open, onClose }) {
  const { filters, handleSource, handleRegion, applyFilters, handleChangeCC } = useFilterContext();
  const theme = useTheme();
  const isXsScreen = useMediaQuery(theme.breakpoints.down('sm'));

  const drawerWidth = isXsScreen ? '100vw' : '450px';

  return (
    <Drawer
      anchor="right"
      open={open}
      onClose={onClose}
      sx={{
        '& .MuiDrawer-paper': {
          width: drawerWidth,
          boxSizing: 'border-box',
        },
      }}
    >
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'flex-start',
          padding: '1rem',
          backgroundColor: 'white',
          height: '100%',
        }}
      >
        <Box sx={{ width: '100%', display: 'flex', justifyContent: 'flex-start', marginBottom: '1rem' }}>
          <IconButton
            onClick={onClose}
            sx={{
              color: theme.palette.grey[800],
              padding: '10px',
            }}
          >
            <ArrowBackIosIcon />
          </IconButton>
        </Box>

        <Box sx={{ width: 1, display: 'flex', justifyContent: 'center', marginBottom: '1rem' }}>
          <Box sx={{ maxWidth: '100%' }}>
            <SourcesCheckBoxesGroup states={filters.sourceValues} handleChange={handleSource} />
          </Box>
        </Box>
        <Box sx={{ width: 1, display: 'flex', justifyContent: 'center', marginBottom: '1rem' }}>
          <Box sx={{ maxWidth: '100%' }}>
            <RegionsCheckBoxesGroup states={filters.regionValues} handleChange={handleRegion} />
          </Box>
        </Box>
        <Box sx={{ width: 1, display: 'flex', justifyContent: 'center', marginBottom: '1rem' }}>
          <Box sx={{ maxWidth: '100%' }}>
            <CCRadioButtonsGroup value={filters.ccValue} handleChange={handleChangeCC} />
          </Box>
        </Box>
        <Box sx={{ width: '100%', mt: 2, display: 'flex', justifyContent: 'center' }}>
          <Button
            sx={{
              backgroundColor: 'black',
              color: '#ffffff',
              '&:hover': { backgroundColor: '#0a5968' },
              borderRadius: '20px',
              textTransform: 'none',
              boxShadow: 'none',
              fontSize: '1rem',
              fontWeight: 'bold',
              padding: '6px 12px',
            }}
            variant="contained"
            color="primary"
            onClick={() => {
              applyFilters();
              onClose();
            }}
          >
            Apply Filters
          </Button>
        </Box>
      </Box>
    </Drawer>
  );
}
