import React from 'react';
import { useAppContext } from './Contexts/AppContext';
import { Box, Chip, Paper, Typography, Icon } from '@mui/material';
import StarIcon from '@mui/icons-material/Star';
import DeleteIcon from '@mui/icons-material/Delete';

const SavedSearchesList = () => {
  const { savedSearches, searchFromSearchBar, setSavedSearches, setSearchBarInput } = useAppContext();

  const handleDelete = (indexToDelete) => {
    setSavedSearches((prevSearches) => prevSearches.filter((_, index) => index !== indexToDelete));
  };

  const handleSearchClick = (search) => {
    setSearchBarInput(search); // Update the search bar input
    searchFromSearchBar(search); // Trigger the search
  };

  return (
    <Paper elevation={1} sx={{ padding: 2, marginTop: 7 }}>
      <Typography variant="h6" gutterBottom>
      </Typography>
      <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 1 }}>
        {savedSearches.map((search, index) => (
          <Chip
            key={index}
            icon={
              <Icon>
                <StarIcon sx={{ color: '#FFD700' }} />
              </Icon>
            } // Set the star icon color to yellow
            label={search}
            onClick={() => handleSearchClick(search)}
            onDelete={() => handleDelete(index)}
            deleteIcon={<DeleteIcon />}
            variant="outlined"
            sx={{ cursor: 'pointer' }}
          />
        ))}
      </Box>
    </Paper>
  );
};

export default SavedSearchesList;
